<template>
  <h1 class="title">Registration</h1>
  <div class="center">
    <img src="../assets/logo.png" alt="no photo" />
  </div>
  <q-banner
    dense
    inline-actions
    class="text-white bg-negative"
    v-if="isAlertDialog"
  >
    <p>{{ message }}</p>
    <template v-slot:action>
      <q-btn
        flat
        color="white"
        label="Close"
        @click="isAlertDialog = false"
        class="right"
      />
    </template>
  </q-banner>
  <q-card-section>
    <div class="loc">
      <div class="center">
        <h2>Where</h2>
        <p>Ivey Spencer Leadership Centre</p>
        <p>551 Windermere Rd</p>
        <p>London, ON N5X 2T1</p>
      </div>
      <div class="center">
        <h2>When</h2>
        <p> Saturday, Feb 3rd, 2024</p>
        <p>08:30 a.m. - 03:00 p.m.</p>
      </div>
    </div>
    <q-separator />
    <br />
    <div class="flex">
      <q-input outlined v-model="firstName" label="First Name" class="input" />
      <q-input outlined v-model="lastName" label="Last Name" class="input" />
    </div>
    <div class="flex">
      <q-input outlined v-model="email" label="Email" class="input" />
      <q-input
        outlined
        v-model="phone"
        label="Phone (Optional)"
        mask="(###) ### - ####"
        class="input"
      />
    </div>
  </q-card-section>
  <q-separator />
  <q-card-section>
    <h2>Hotel Reservation</h2>
    <div class="check">
      <q-checkbox v-model="hotel" />
      <label>
        Please <b>check</b> this box if you would like to book a hotel room on
        <b>Friday February 2nd</b>.
      </label>
    </div>
  </q-card-section>
  <div class="center">
    <div v-if="!spin" style="margin-bottom: 10px">
      <q-btn
        unelevated
        label="Submit"
        color="primary"
        @click="submit"
        size="1.6rem"
        padding="5px 15px"
      />
    </div>
    <div v-else style="margin-bottom: 10px">
      <q-spinner color="primary" size="3em" :thickness="10" />
    </div>
  </div>
  <q-dialog v-model="isSuccess">
    <q-card>
      <q-card-section>
        <div class="text-h6">Registration Succeeded</div>
      </q-card-section>
      <q-card-section class="q-pt-none">
        <p>You have been registered successfully</p>
        <p>Your name and email entered our list of attendants</p>
        <p>
          Your reference code is:
          <span
            ><b>{{ refCode }}</b></span
          >
        </p>
        <p>Please, keep your reference code safe for future use</p>
        <p>Thank you</p>
      </q-card-section>
      <q-card-actions align="right" class="right">
        <q-btn flat label="Close" color="primary" @click="close" />
        <q-btn unelevated label="Print" color="primary" @click="print" />
      </q-card-actions>
    </q-card>
  </q-dialog>
  <Footer />
</template>

<script>
import { ref, onMounted } from "vue";
import Footer from "../components/Footer.vue";
import { db, auth } from "../firebase/config";
import { signInAnonymously } from "firebase/auth";
import { serverTimestamp } from "firebase/firestore";
import { useRouter } from "vue-router";
import {
  doc,
  setDoc,
  query,
  where,
  collection,
  getDocs,
} from "firebase/firestore";
export default {
  components: { Footer },
  setup() {
    const router = useRouter();
    // const attendOption = ref("");
    const isAlert = ref(false);
    const isAlertDialog = ref(false);
    const isSuccess = ref(false);
    const spin = ref(false);
    const message = ref("");
    const isPerson = ref(false);
    // const dates = ref([]);
    const firstName = ref("");
    const lastName = ref("");
    const email = ref("");
    const phone = ref("");
    const disclaimer1 = ref(false);
    const disclaimer2 = ref(false);
    const hotel = ref(false);
    const refCode = ref(0);
    const submit = async () => {
      if (!firstName.value || !lastName.value || !email.value) {
        isAlertDialog.value = true;
        message.value = "Please fill all the required fields.";
      } else if (!email.value.match(/\S+@\S+\.\S+/)) {
        isAlertDialog.value = true;
        message.value = "Please enter a valid email.";
      } else {
        try {
          isAlertDialog.value = false;
          spin.value = true;
          await signInAnonymously(auth);
          refCode.value = Math.floor(Math.random() * 10000);
          const q = query(
            collection(db, "londonLiverGi"),
            where("refCode", "==", refCode.value)
          );
          const docs = await getDocs(q);
          if (!docs.empty) {
            refCode.value = Math.floor(Math.random() * 10000);
          }
          await setDoc(doc(db, `londonLiverGi/${email.value}`), {
            firstName: firstName.value,
            lastName: lastName.value,
            email: email.value,
            phone: phone.value,
            refCode: refCode.value.toString(),
            hotel: hotel.value,
            time: serverTimestamp(),
          });
          firstName.value = "";
          lastName.value = "";
          email.value = "";
          phone.value = "";
          isPerson.value = false;
          isSuccess.value = true;
          spin.value = false;
        } catch (error) {
          console.log(error);
        }
      }
    };
    const close = () => {
      router.push({ name: "Home" });
    };
    const print = () => {
      window.print();
    };
    onMounted(() => {
      window.scrollTo(0, 0);
    });
    return {
      // attendOption,
      // register,
      isAlert,
      isAlertDialog,
      isSuccess,
      spin,
      message,
      isPerson,
      submit,
      firstName,
      lastName,
      email,
      phone,
      hotel,
      disclaimer1,
      disclaimer2,
      refCode,
      print,
      close,
    };
  },
};
</script>

<style lang="scss" scoped>
* {
  font-family: "Open Sans", sans-serif;
}
.title {
  margin-top: 40px;
  font-size: 60px;
  font-family: "Lato", sans-serif;
  text-align: center;
  color: #85053b;
}
.sub-title {
  width: 60%;
  margin: 20px auto 0;
  font-size: 22px;
}
.center {
  text-align: center;
  img {
    width: 180px;
  }
}
.radios {
  width: 350px;
  margin: auto;
  * {
    text-align: left;
  }
  label {
    font-size: 20px;
    font-weight: bold;
  }
}
.reg-button {
  padding: 10px 20px;
  margin: 20px auto;
  font-size: 20px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
.right {
  margin-right: 30px;
}
.dialog {
  width: 60%;
  max-width: 60%;
}
.loc {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  * {
    flex: 1;
  }
  p {
    margin-bottom: 5px;
  }
}
.check {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  margin-bottom: 10px;
  label {
    font-size: 16px;
    margin-top: 5px;
  }
}
.flex {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 10px 5px;
}
.input {
  width: 300px;
  margin-bottom: 5px;
}
@media (max-width: 750px) {
  .sub-title {
    width: 70%;
  }
  .dialog {
    width: 80%;
    max-width: 80%;
  }
}
@media (max-width: 500px) {
  .sub-title {
    width: 90%;
  }
  .dialog {
    width: 95%;
    max-width: 95%;
  }
  * {
    h2 {
      font-size: 20px !important;
    }
    p {
      font-size: 12px;
    }
  }
}
</style>
