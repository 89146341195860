import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAkAGHBQYpEJ4f8zvfQRUQk5lkKmyksBbs",
  authDomain: "minds-c85e5.firebaseapp.com",
  projectId: "minds-c85e5",
  storageBucket: "minds-c85e5.appspot.com",
  messagingSenderId: "537902541731",
  appId: "1:537902541731:web:59a7c6a57b0e2b7ea90816",
  measurementId: "G-ECXST848BP",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };
